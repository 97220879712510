<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" md="6">
				<v-textarea rows="20" v-model="listaCups" filled dense hide-details="auto" :rules="[rules.req]"></v-textarea>
			</v-col>

			<v-col cols="12" md="6">
				<v-subheader>CUPS:</v-subheader>
				<v-list dense>
					<v-list-item v-for="CUPS in parsedCups">
						<v-list-item-content>
							<v-list-item-title
								:class="[CUPS.valido === true ? 'success--text' : 'error--text']"
							>{{ CUPS.cups }}</v-list-item-title>
						</v-list-item-content>
						<v-list-item-action v-if="CUPS.valido !== true" :class="[CUPS.valido === true ? 'success--text' : 'error--text']">
							<!-- <v-alert dense text type="error" class="ma-0 pa-1"> -->
							<div class="d-flex">
								<v-icon color="error" left small>mdi-alert</v-icon>
								<small>{{ CUPS.valido }}</small>
							</div>
							<!-- </v-alert> -->
						</v-list-item-action>
					</v-list-item>
				</v-list>
				<div class="d-flex mt-auto">
					<v-spacer></v-spacer>
					<v-btn :loading="loading" large color="primary" :disabled="parsedCups.some(c => c.valido !== true)" @click.stop="buscarMulticups">
						<v-icon>mdi-magnify</v-icon>
						Buscar
					</v-btn>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { req, cups as validateCups } from '@/utils/validations.js';
import { downloadFile } from '@/utils/index.js';

export default {
	data() {
		return {
			listaCups: '',
			loading: false,
			rules: { req }
		}
	},
	computed: {
		parsedCups() {
			const cups = [...new Set(this.listaCups.split('\n'))];
			return cups.filter(c => !!c).map(c => ({ cups: c, valido: validateCups(c) }))
		}
	},
	methods: {
		buscarMulticups() {
			this.loading = true;
			axios({
				url: `${process.env.VUE_APP_API_URL}/multiCUPS.php`,
				method: 'GET',
				params: {
					token: this.$store.getters.getToken,
					cups: this.parsedCups.map(c => c.cups).join(','),
				},
				responseType: 'blob'
			}).then(({data}) => {
				downloadFile( new File([data], `MULTICUPS_${Date.now()}.xlsx`, { type: 'application/excel' }) )
				this.loading = false;
			})
		}
	}
}
</script>